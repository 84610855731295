import React from "react";


function MainSection(props) {

  return (
    <section>
        <div className="container">
            <div className="sp-hero-bg" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
            <h1>{props.content.mainTitle}</h1>

            <div className="row">
                <div className="col-12 col-md-4" />
                <div className="col-12 col-md-8">
                    <p>{props.content.mainText}</p>
                </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default MainSection;
