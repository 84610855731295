import React from "react";
import { Link } from "react-router-dom";
import LipaLater from "../assets/img/lipalater.png";
import Ecobank from "../assets/img/ecobank.png";
import Dash from "../assets/img/dash.png";
import HustleSasa from "../assets/img/hustlesasa.png";
import Ntl from "../assets/img/ntl.png";
import Agrikore from "../assets/img/agrikore.png";


function MoreProjects(props) {

  return (
    <section>
        <div className="container more-projects-padd">
            <h3>OTHER PROJECTS</h3>

            <div className="row mt-5" data-aos="fade-up" data-aos-once="true" data-aos-easing="ease-out-cubic" data-aos-duration="800">
                <div className="col-12 col-md-6 mt-4">
                    {
                        props.selectedPortfolio === 'dash' ? (
                            <Link to={`/portfolio/lipa-later`}>
                                <img src={LipaLater} alt="" />
                            </Link>
                        ) : (
                            null
                        )
                    }
                    {
                        props.selectedPortfolio === 'lipa-later' ? (
                            <img src={Ecobank} alt="" />
                        ) : (
                            null
                        )
                    }
                    {
                        props.selectedPortfolio === 'hustle-sasa' ? (
                            <Link to={`/portfolio/dash`}>
                                <img src={Dash} alt="" />
                            </Link>
                        ) : (
                            null
                        )
                    }
                    {
                        props.selectedPortfolio === 'agrikore' ? (
                            // <Link to={`/portfolio/agrikore`}>
                                <img src={Ecobank} alt="" />
                            // </Link>
                        ) : (
                            null
                        )
                    }
                </div>
                <div className="col-12 col-md-6 mt-4">
                    {
                        props.selectedPortfolio === 'dash' ? (
                            <img src={Ntl} alt="" />
                        ) : (
                            null
                        )
                    }
                    {
                        props.selectedPortfolio === 'lipa-later' ? (
                            <Link to={`/portfolio/hustle-sasa`}>
                                <img src={HustleSasa} alt="" />
                            </Link>
                        ) : (
                            null
                        )
                    }
                    {
                        props.selectedPortfolio === 'hustle-sasa' ? (
                            <Link to={`/portfolio/agrikore`}>
                                <img src={Agrikore} alt="" />
                            </Link>
                        ) : (
                            null
                        )
                    }
                    {
                        props.selectedPortfolio === 'agrikore' ? (
                            <img src={Ntl} alt="" />
                        ) : (
                            null
                        )
                    }
                </div>
            </div>

        </div>
    </section>
  )
}

export default MoreProjects;
