import React from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import SinglePortfolio from './pages/SinglePortfolio';

function App() {
  const [lightMode, setLightMode] = React.useState(true);

  const switchMode = () => {
    setLightMode(!lightMode);
  };

  return (
    <div className={lightMode ? "" : "dark-mode-toggle"}>
      <Routes>
        <Route path="/" element={<Home switchMode={() => switchMode()} lightMode={lightMode} />} exact />
        <Route path="/portfolio/:work" element={<SinglePortfolio switchMode={() => switchMode()} lightMode={lightMode} />} exact />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

export default App;
