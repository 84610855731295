import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';


function ImageSection(props) {

  return (
    <section>
      <div className="full-section-bg" /* style={{ backgroundImage: `url(${props.content.sectionImg})` }} */>
        {/* <img src={props.content.sectionImg} alt="" /> */}

        <LazyLoadImage
          alt=""
          src={props.content.sectionImg} />
      </div>
    </section>
  )
}

export default ImageSection;
