import HustleSasa from "../assets/img/hustlesasa/hustlesasabg.png";
import HomeOne from "../assets/img/hustlesasa/homeone.png";
import HomeTwo from "../assets/img/hustlesasa/hometwo.png";
import HomeThree from "../assets/img/hustlesasa/homethree.png";

import OrdersOne from "../assets/img/hustlesasa/ordersone.png";
import OrdersTwo from "../assets/img/hustlesasa/orderstwo.png";
import OrderstThree from "../assets/img/hustlesasa/ordersthree.png";
import OrdersFour from "../assets/img/hustlesasa/ordersfour.png";
import OrdersFive from "../assets/img/hustlesasa/ordersfive.png";
import OrderstSix from "../assets/img/hustlesasa/orderssix.png";

import ProductsOne from "../assets/img/hustlesasa/productsone.png";
import ProductsTwo from "../assets/img/hustlesasa/productstwo.png";
import ProductsThree from "../assets/img/hustlesasa/productsthree.png";

import HustleOne from "../assets/img/hustlesasa/hustleone.png";
import HustleTwo from "../assets/img/hustlesasa/hustletwo.png";
import HustleThree from "../assets/img/hustlesasa/hustlethree.png";

import Storefront from "../assets/img/hustlesasa/storefront.png";

export const hustleSasaMainTitleContent = {
    mainTitle: "Hustle Sasa",
    mainText: "Enabling creators in Africa to sell directly to their fans."
};

export const hustleSasaImageContent = {
    sectionImg: HustleSasa
};

export const hustleSasaOverviewContent = {
        overviewList: [
            {
                ovMainTitle: "Overview",
                workDuration: [
                    {
                        durItem: "2021 – 2022, Freelance Product Designer"
                    }
                ],
                ovMainText: [
                    {
                        oMItem: "Dash is a unified and accessible payments platform built to increase efficiency and accessibility for the estimated 1.3 billion Africans currently transacting via digital payments. Creating a unique alternative payment network that brings together mobile money and traditional banks, to facilitate the processing of transactions for consumers and businesses."
                    }
                ],
            },
            {
                ovMainTitle: "The Challenge",
                workDuration: null,
                ovMainText: [
                    {
                        oMItem: "Develop a UI/UX redesign solution for Lipa Later that enhances its potential to empower African businesses and enhance financial inclusion. The objective is to create an intuitive and visually appealing interface that seamlessly integrates with e-commerce, catering to the diverse needs of both consumers and retailers."
                    },
                    {
                        oMItem: "The design should focus on simplifying the credit accessibility process, optimizing the online shopping journey, and fostering trust in the alternative payment system. By prioritizing user-centric design principles, the goal is to amplify the platform's impact on reducing friction and increasing purchasing power."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "Scaling the team"
            },
            {
                rolItem: "Design Leadership"
            },
            {
                rolItem: "Strategic planning"
            },
        ],
        recognition: [
            {
                recogItem: "Kenyan BNPL startup Lipa Later secures $12m funding for expansion"
            }
        ]
};

export const hustleSasaDetailsContent = [
    {
        detailsTitle: "Home",
        paragraphList: [
            {
                subTitle: null,
                problemStatement: null,
                subText: [
                    {
                        stItem: "I created an innovative homepage that prioritizes a comprehensive and user-friendly experience. This design provides users a clear view of their credit limit, showcases trending items, and features partner shops. It also offers insights into upcoming payments."
                    },
                    {
                        stItem: "Furthermore, the homepage enables real-time tracking of payment statuses, sending notifications for on-track payments and alerts for overdue ones. The intuitive design and seamless interaction empower users with transparency, control over spending, and a convenient interface for effective management of payments and shopping preferences."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Design Direction"
            },
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "UX/UI Design"
            }
        ],
        detailsFullSegmentImage: false,
        detailsSectionImage: null,
        detailsImages: [
            HomeOne, HomeTwo, HomeThree
        ],
        lastItem: false
    },
    {
        detailsTitle: "Manage Orders",
        paragraphList: [
            {
                subTitle: null,
                problemStatement: null,
                subText: [
                    {
                        stItem: "I created a user-friendly shopping experience that allows users to easily discover a variety of brands and products. An intuitive search function ensures swift item discovery. Furthermore, users can input their delivery location, choose flexible Buy Now Pay Later payment plans, and track deliveries in real-time. This approach simplifies the shopping journey, emphasizing convenience and personalization."
                    },
                    {
                        stItem: "The aim of the shopping page was to revolutionize online shopping by providing a convenient and personalized experience. Customers can effortlessly explore, purchase, and monitor their orders, thereby boosting both satisfaction and engagement. With a strong focus on user needs, the page enhances the overall online shopping experience."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Design Direction"
            },
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "UX/UI Design"
            }
        ],
        detailsFullSegmentImage: false,
        detailsSectionImage: null,
        detailsImages: [
            OrdersOne, OrdersTwo, OrderstThree, OrdersFour, OrdersFive, OrderstSix
        ],
        lastItem: false
    },
    {
        detailsTitle: "Manage your orders",
        paragraphList: [
            {
                subTitle: "The solution:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "We understand that paying bills can be messy and stressful. That’s why we’ve made it easy to manage your bills on Dash. With organized and automatic payments, you’ll never have to worry about overdue or missed payments again. Simply schedule your recurring or one-time bills, and they’ll automatically be paid from your account balance."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Design Direction"
            },
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "UX/UI Design"
            }
        ],
        detailsFullSegmentImage: false,
        detailsSectionImage: null,
        detailsImages: [
            ProductsOne, ProductsTwo, ProductsThree
        ],
        lastItem: false
    },
    {
        detailsTitle: "Manage your hustle",
        paragraphList: [
            {
                subTitle: "The problem:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "Our users are based across Africa and need a banking service that doesn’t require a lengthly onboarding process. As a regulated banking service, we are however required to understand certain details about our customers to ensure that everyone is using the service in a way that complies with the law. This is referred to as ‘KYC’ (Know your customer)."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Design Direction"
            },
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "UX/UI Design"
            }
        ],
        detailsFullSegmentImage: false,
        detailsSectionImage: null,
        detailsImages: [
            HustleOne, HustleTwo, HustleThree
        ],
        lastItem: false
    },
    {
        detailsTitle: "Storefront",
        paragraphList: [
            {
                subTitle: "The problem:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "Our users are based across Africa and need a banking service that doesn’t require a lengthly onboarding process. As a regulated banking service, we are however required to understand certain details about our customers to ensure that everyone is using the service in a way that complies with the law. This is referred to as ‘KYC’ (Know your customer)."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Design Direction"
            },
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "UX/UI Design"
            }
        ],
        detailsFullSegmentImage: true,
        detailsSectionImage: Storefront,
        detailsImages: [],
        lastItem: true
    }
];