import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';


function DetailsSection(props) {

  return (
    <section>
        {
          props.content.map((item, key) => (
            <div className={item.lastItem === true ? "portfolio-border-bottom" : ""} key={key}>
              <div className="text-section-padd">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-md-7">
                        <h2>{item.detailsTitle}</h2>

                        {
                          item.paragraphList.map((itemL, key) => (
                            <React.Fragment key={key}>
                              <h6>{itemL.subTitle}</h6>

                              <p className="problem-statement-para">{itemL.problemStatement}</p>

                              {
                                itemL.subText.map((itemSubText, key) => (
                                  <p className="main-text-para" key={key}>{itemSubText.stItem}</p>
                                ))
                              }
                            </React.Fragment>
                          ))
                        }
                    </div>
                    {/* <div className="col-12 col-md-1"></div> */}
                    {/* <div className="col-12 col-md-3"> */}
                      {
                        item.role === null ? (
                          <>
                            <div className="col-12 col-md-1"></div>
                            <div className="col-12 col-md-4 pt-5">
                              {/* <img src={item.roleImg} alt="" /> */}

                              <LazyLoadImage
                                alt=""
                                src={item.roleImg} />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-12 col-md-2"></div>
                            <div className="col-12 col-md-3">
                              <h5>My Role</h5>

                              <hr />

                              {
                                item.role.map((itemR, key) => (
                                  <p className="main-text-para" key={key}>{`- ${itemR.rolItem}`}</p>
                                ))
                              }
                            </div>
                          </>
                        )
                      }
                    {/* </div> */}
                  </div>

                  {
                    item.breakdownSegment === true ? (
                      <div className="row mt-5 pt-5">
                        {
                          item.breakdownSegmentItems.map((itemB, key) => (
                            <div className="col-12 col-md-3" key={key}>
                              <div className="breakdown-segment-div">
                                <div className="breakdown-segment-img mb-4">
                                  {/* <img src={itemB.breakdownImg} alt="" /> */}

                                  <LazyLoadImage
                                    alt=""
                                    src={itemB.breakdownImg} />
                                </div>
                                <div className="breakdown-segment-title mb-3">
                                  <h4>{itemB.breakdownTitle}</h4>
                                </div>
                                <div className="breakdown-segment-text">
                                  <p>{itemB.breakdownText}</p>
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    ) : (
                      null
                    )
                  }
                </div>

                {
                  item.detailsFullSegmentImage === true ? (
                    <div className="container-fluid">
                      <div className="row portfolio-image-div">
                        <div className="col-12 col-md-12 reset-padding">
                          {/* <img src={item.detailsSectionImage} alt="" /> */}

                          <LazyLoadImage
                            alt=""
                            src={item.detailsSectionImage} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="container">
                      <div className="row portfolio-image-div mb-5">
                        {
                          item.detailsImages.map((itemDI, key) => (
                            <div className="col-12 col-md-4" key={key}>
                              {/* <img src={itemDI} alt="" /> */}

                              <LazyLoadImage
                                alt=""
                                className="app-image-deco mb-5"
                                src={itemDI} />
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  )
                }
                
                <div className="container">
                  <div className="separator-hr-div">
                    {item.lastItem === true ? (null) : (item.detailsFullSegmentImage === true ? (null) : (<hr />))}
                  </div>
                </div>
              </div>
            </div>
          ))
        }
    </section>
  )
}

export default DetailsSection;
