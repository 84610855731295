import React from "react";


function OverviewSection(props) {

  return (
    <section>
        <div className="text-section-padd">
          <div className="container">
            <div className="row mb-5">
              <div className="col-12 col-md-7">
                  {
                    props.content.overviewList.map((item, key) => (
                      <React.Fragment key={key}>
                        <h5>{item.ovMainTitle}</h5>

                        {
                          item.workDuration?.map((item, key) => (
                            <p className="work-duration-para" key={key}>{item.durItem}</p>
                          ))
                        }

                        {
                          item.ovMainText?.map((itemP, key) => (
                            <p className="main-text-para" key={key}>{itemP.oMItem}</p>
                          ))
                        }
                      </React.Fragment>
                    ))
                  }
              </div>
              <div className="col-12 col-md-2"></div>
              <div className="col-12 col-md-3">
                  <h5>My Role</h5>

                  <hr />

                  {
                    props.content.role.map((itemR, key) => (
                      <p className="main-text-para" key={key}>{`- ${itemR.rolItem}`}</p>
                    ))
                  }

                  {
                    props.content.recognition ? (
                      <div className="recognition-div">
                        <h5>Recognition</h5>

                        <hr />

                        {
                          props.content.recognition.map((itemRe, key) => (
                            <a href={itemRe.recogLink} target="_blank" rel="noreferrer" className="main-text-para recognition-div-link" key={key}>{`- ${itemRe.recogItem}`}</a>
                          ))
                        }
                      </div>
                    ) : (
                      null
                    )
                  }
              </div>
            </div>

            <div className="separator-hr-div">
              <hr />
            </div>
          </div>
        </div>
      </section>
  )
}

export default OverviewSection;
