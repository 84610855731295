import React from "react";
import Dribbble from "../assets/img/dribbble.svg";
import LinkedIn from "../assets/img/linkedin.svg";
import Email from "../assets/img/email.svg";


function Footer() {

  return (
    <div className="footer-bg">
      <div className="container text-center">
        <h4>Let's Connect</h4>

        <p>Feel free to reach out for collaborations or just a friendly hello 😀</p>

        <div>
          <div className="social-divs">
            <a href="https://dribbble.com/victorshiwani" target="_blank" rel="noreferrer">
              <img src={Dribbble} alt="" /> <p>Dribbble</p>
            </a>
          </div>

          <div className="social-divs">
            <a href="https://www.linkedin.com/in/victorshiwani" target="_blank" rel="noreferrer">
              <img src={LinkedIn} alt="" /> <p>LinkedIn</p>
            </a>
          </div>

          <div className="social-divs">
            <a href="mailto:victorshiwani@gmail.com?subject=Hello" target="_blank" rel="noreferrer">
              <img src={Email} alt="" /> <p>Email Me</p>
            </a>
          </div>
        </div>

        <p className="created-paragraph">© {new Date().getFullYear()}  •  Madebyshiwani</p>
      </div>
    </div>
  )
}

export default Footer;
