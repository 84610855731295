import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import Navbar from "../components/Navbar";
import MainSection from "../components/MainSection";
import ImageSection from "../components/ImageSection";
import OverviewSection from "../components/OverviewSection";
import DetailsSection from "../components/DetailsSection";
import Footer from "../components/Footer";

import { 
  dashMainTitleContent, 
  dashImageContent, 
  dashOverviewContent, 
  dashDetailsContent 
} from "../data/dashcontent";
import { 
  lipaLaterMainTitleContent, 
  lipaLaterImageContent, 
  lipaLaterOverviewContent, 
  lipaLaterDetailsContent 
} from "../data/lipaLaterContent";
import { 
  hustleSasaMainTitleContent, 
  hustleSasaImageContent, 
  hustleSasaOverviewContent, 
  hustleSasaDetailsContent 
} from "../data/hustleSasaContent";
import { 
  agrikoreMainTitleContent, 
  agrikoreImageContent, 
  agrikoreOverviewContent, 
  agrikoreDetailsContent 
} from "../data/agrikoreContent";
import MoreProjects from "../components/MoreProjects";

function SinglePortfolio(props) {
  const params = useParams();
  /* const [projectIncluded, setProjectIncluded] = React.useState(null);

  useEffect(() => {
    const countriesList = ['dash'];
    setProjectIncluded(countriesList.includes(params.work));
  }); */

  useEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <div>
      <div data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
        <Navbar 
          switchMode={() => props.switchMode()}
          lightMode={props.lightMode}
          activeLink="home" />
      </div>


      {/* Main Section */}
      {
        params.work === 'dash' ? <MainSection content={dashMainTitleContent} /> : null
      }
      {
        params.work === 'lipa-later' ? <MainSection content={lipaLaterMainTitleContent} /> : null
      }
      {
        params.work === 'hustle-sasa' ? <MainSection content={hustleSasaMainTitleContent} /> : null
      }
      {
        params.work === 'agrikore' ? <MainSection content={agrikoreMainTitleContent} /> : null
      }


      {/* Image Section */}
      {
        params.work === 'dash' ? <ImageSection content={dashImageContent} /> : null
      }
      {
        params.work === 'lipa-later' ? <ImageSection content={lipaLaterImageContent} /> : null
      }
      {
        params.work === 'hustle-sasa' ? <ImageSection content={hustleSasaImageContent} /> : null
      }
      {
        params.work === 'agrikore' ? <ImageSection content={agrikoreImageContent} /> : null
      }


      {/* Overview Section */}
      {
        params.work === 'dash' ? <OverviewSection content={dashOverviewContent} /> : null
      }
      {
        params.work === 'lipa-later' ? <OverviewSection content={lipaLaterOverviewContent} /> : null
      }
      {
        params.work === 'hustle-sasa' ? <OverviewSection content={hustleSasaOverviewContent} /> : null
      }
      {
        params.work === 'agrikore' ? <OverviewSection content={agrikoreOverviewContent} /> : null
      }


      {/* Details Section */}
      {
        params.work === 'dash' ? <DetailsSection content={dashDetailsContent} /> : null
      }
      {
        params.work === 'lipa-later' ? <DetailsSection content={lipaLaterDetailsContent} /> : null
      }
      {
        params.work === 'hustle-sasa' ? <DetailsSection content={hustleSasaDetailsContent} /> : null
      }
      {
        params.work === 'agrikore' ? <DetailsSection content={agrikoreDetailsContent} /> : null
      }

      <MoreProjects selectedPortfolio={params.work} />

      <section>
        <div className="container hr-div">
          <hr />
        </div>
      </section>

      <section>
        <Footer />
      </section>

    </div>
  );
}

export default SinglePortfolio;
