import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.svg";


function Navbar(props) {

  return (
    <nav className="navbar navbar-expand-md navbar-dark" style={{ position: 'fixed', width: '100%', zIndex: 3000 }}>
      <div className="container">
        <Link to="/" className="navbar-brand">
          <img src={Logo} alt="" />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar6">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-collapse collapse justify-content-stretch" id="navbar6">
            {/* <ul className="navbar-nav mx-auto">
                <li className={"nav-item" + (props.activeLink === 'services' ? " active" : "")}>
                  <Link className="nav-link" to="/services">Services <span className="sr-only">Home</span></Link>
                </li>
                <li className={"nav-item" + (props.activeLink === 'projects' ? " active" : "")}>
                  <Link className="nav-link" to="/projects">Projects</Link>
                </li>
                <li className={"nav-item" + (props.activeLink === 'about' ? " active" : "")}>
                  <Link className="nav-link" to="/about">About</Link>
                </li>
            </ul> */}
            <ul className="navbar-nav ml-auto">
                <li className={"nav-item" + (props.activeLink === 'work' ? " active" : "")}>
                  <Link className="nav-link" to="/">work</Link>
                </li>
                <li className={"nav-item" + (props.activeLink === 'about' ? " active" : "")}>
                  <Link className="nav-link" to="/">about</Link>
                </li>
                <li className={"nav-item" + (props.activeLink === 'contact' ? " active" : "")}>
                  <Link className="nav-link" to="mailto:victorshiwani@gmail.com">contact</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/" onClick={(e) => { e.preventDefault(); props.switchMode() }}><i class={props.lightMode ? ("las la-moon") : ("las la-sun")}></i></Link>
                </li>
            </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar;
