import LipaLater from "../assets/img/lipalater/lipalater.png";
import HomeOne from "../assets/img/lipalater/homeone.png";
import HomeTwo from "../assets/img/lipalater/hometwo.png";
import HomeThree from "../assets/img/lipalater/homethree.png";

import ShopOne from "../assets/img/lipalater/shopone.png";
import ShopTwo from "../assets/img/lipalater/shoptwo.png";
import ShopThree from "../assets/img/lipalater/shopthree.png";
import ShopFour from "../assets/img/lipalater/shopfour.png";
import ShopFive from "../assets/img/lipalater/shopfive.png";
import ShopSix from "../assets/img/lipalater/shopsix.png";

import FacOne from "../assets/img/lipalater/facone.png";
import FacTwo from "../assets/img/lipalater/factwo.png";
import FacThree from "../assets/img/lipalater/facthree.png";

import WalOne from "../assets/img/lipalater/walone.png";
import WalTwo from "../assets/img/lipalater/waltwo.png";
import WalThree from "../assets/img/lipalater/walthree.png";

export const lipaLaterMainTitleContent = {
    mainTitle: "Lipa Later",
    mainText: "Empowering shoppers to \"Buy Now, Pay Later\" with flexible monthly instalments"
};

export const lipaLaterImageContent = {
    sectionImg: LipaLater
};

export const lipaLaterOverviewContent = {
        overviewList: [
            {
                ovMainTitle: "Overview",
                workDuration: [
                    {
                        durItem: "2021 – 2022, Freelance Product Designer"
                    }
                ],
                ovMainText: [
                    {
                        oMItem: "Lipa Later is an African company that offers financial solutions and alternative payment methods to consumers. They empower African businesses through e-commerce support, financial inclusion, and a centralized shopping platform. Lipa Later has enhanced consumer access to credit, improved the shopping experience, and increased retail partner sales by up to 30%."
                    }
                ],
            },
            {
                ovMainTitle: "THE PROBLEM",
                workDuration: null,
                ovMainText: [
                    {
                        oMItem: "Develop a UI/UX design solution for Lipa Later that enhances its potential to empower African businesses and enhance financial inclusion. The objective is to create an intuitive and visually appealing one-stop shop for finding new products, getting approved for loans, and keeping track of payments."
                    },
                    {
                        oMItem: "The design should focus on simplifying the credit accessibility process, optimizing the online shopping journey, and fostering trust in the alternative payment system. By prioritizing user-centric design principles, the goal is to amplify the platform's impact on reducing friction and increasing purchasing power."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "Design Leadership"
            },
            {
                rolItem: "Design Research"
            },
        ],
        recognition: [
            {
                recogItem: "Kenyan BNPL startup Lipa Later secures $12m funding for expansion",
                recogLink: "https://disrupt-africa.com/2022/01/13/kenyan-bnpl-startup-lipalater-secures-12m-funding-for-expansion/"
            }
        ]
};

export const lipaLaterDetailsContent = [
    {
        detailsTitle: "Homepage",
        paragraphList: [
            {
                subTitle: null,
                problemStatement: null,
                subText: [
                    {
                        stItem: "I created an innovative homepage that prioritizes a comprehensive and user-friendly experience. This design provides users a clear view of their credit limit, showcases trending items, and features partner shops. It also offers insights into upcoming payments."
                    },
                    {
                        stItem: "Furthermore, the homepage enables real-time tracking of payment statuses, sending notifications for on-track payments and alerts for overdue ones. The intuitive design and seamless interaction empower users with transparency, control over spending, and a convenient interface for effective management of payments and shopping preferences."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Design Direction"
            },
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "UX/UI Design"
            }
        ],
        detailsFullSegmentImage: false,
        detailsSectionImage: null,
        detailsImages: [
            HomeOne, HomeTwo, HomeThree
        ],
        lastItem: false
    },
    {
        detailsTitle: "Get approved for a loan instantly",
        paragraphList: [
            {
                subTitle: null,
                problemStatement: null,
                subText: [
                    {
                        stItem: "I developed a cutting-edge user experience that enable shoppers to prequalify for credit after verifying their income status, so they know exactly how much they’re approved to purchase with Lipa Later before the shopping journey begins."
                    },
                    {
                        stItem: "The solution enable shoppers to verify their income through two convenient methods. Firstly, they can upload their three-month bank statement directly within the app. This streamlined process ensures that their financial data is securely and accurately assessed to determine their creditworthiness."
                    },
                    {
                        stItem: "Alternatively, customers can opt for the hassle-free option of connecting their bank account to the app. This not only simplifies the verification process but also enhances security and accuracy. With this method, income verification is done automatically, sparing customers the time and effort of manually submitting documents.",
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Design Direction"
            },
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "UX/UI Design"
            }
        ],
        detailsFullSegmentImage: false,
        detailsSectionImage: null,
        detailsImages: [
            WalOne, WalTwo, WalThree
        ],
        lastItem: true
    },
    {
        detailsTitle: "Discover brands and start shopping easily ",
        paragraphList: [
            {
                subTitle: null,
                problemStatement: null,
                subText: [
                    {
                        stItem: "I created a user-friendly shopping experience that allows users to easily discover a variety of brands and products. An intuitive search function ensures swift item discovery. Furthermore, users can input their delivery location, choose flexible Buy Now Pay Later payment plans, and track deliveries in real-time. This approach simplifies the shopping journey, emphasizing convenience and personalization."
                    },
                    {
                        stItem: "The aim of the shopping page was to revolutionize online shopping by providing a convenient and personalized experience. Customers can effortlessly explore, purchase, and monitor their orders, thereby boosting both satisfaction and engagement. With a strong focus on user needs, the page enhances the overall online shopping experience."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Design Direction"
            },
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "UX/UI Design"
            }
        ],
        detailsFullSegmentImage: false,
        detailsSectionImage: null,
        detailsImages: [
            ShopOne, ShopTwo, ShopThree, ShopFour, ShopFive, ShopSix
        ],
        lastItem: false
    },
    {
        detailsTitle: "Manage and repay loans conveniently ",
        paragraphList: [
            {
                subTitle: null,
                problemStatement: null,
                subText: [
                    {
                        stItem: "I created a user experience that streamlined the process to get, track, and repaying a loan. This user-friendly approach motivated shoppers to plan their next purchases as their repayment progresses."
                    },
                    {
                        stItem: "Customers can easily keep tabs on their loan payment schedules and make payments directly within the app. I also ensured that the due dates for payments are clearly visible, encouraging prompt repayment. This task takes priority in the app's queue whenever a customer logs in. My goal was to design an experience that makes repaying a loan as effortless and speedy as sending a text message."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Design Direction"
            },
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "UX/UI Design"
            }
        ],
        detailsFullSegmentImage: false,
        detailsSectionImage: null,
        detailsImages: [
            FacOne, FacTwo, FacThree
        ],
        lastItem: false
    }
];