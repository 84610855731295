import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Navbar from "../components/Navbar";

import Dash from "../assets/img/dash.png";
import LipaLater from "../assets/img/lipalater.png";
import HustleSasa from "../assets/img/hustlesasa.png";
import Agrikore from "../assets/img/agrikore.png";
import Ntl from "../assets/img/ntl.png";
import Ecobank from "../assets/img/ecobank.png";
import Footer from "../components/Footer";

import PW from "../assets/img/pw.png";
import PWW from "../assets/img/pww.png";

import DTB from "../assets/img/dtb.png";
import DTBW from "../assets/img/dtbw.png";

import Tingg from "../assets/img/tingg.png";
import TinggW from "../assets/img/tinggw.png";

import Prospa from "../assets/img/prospa.png";
import ProspaW from "../assets/img/prospaw.png";

function Home(props) {

  useEffect(() => {
    window.scrollTo(0, 0)
  });

  return (
    <div>
      <div data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
        <Navbar 
          switchMode={() => props.switchMode()}
          lightMode={props.lightMode}
          activeLink="home" />
      </div>

      <section>
        <div className="container">
          <div className="hero-bg" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="500">
            <h1>I’m Shiwani — a curious product designer crafting user-centric magic in the digital realm!🌟</h1>
          </div>
        </div>
      </section>

      <section>
        <div className="container hr-div">
          <hr />
        </div>
      </section>

      <section>
        <div className="container works-div">
          <div className="row" data-aos="fade-up" data-aos-once="true" data-aos-easing="ease-out-cubic" data-aos-duration="800">
            <div className="col-12 col-md-6 mb-4">
              <Link to={`/portfolio/dash`}>
                {/* <img src={Dash} alt="" /> */}

                <LazyLoadImage
                  alt=""
                  src={Dash} />
              </Link>
            </div>
            <div className="col-12 col-md-6 mb-4">
              <Link to={`/portfolio/lipa-later`}>
                {/* <img src={LipaLater} alt="" /> */}

                <LazyLoadImage
                  alt=""
                  src={LipaLater} />
              </Link>
            </div>
          </div>

          <div className="row" data-aos="fade-up" data-aos-once="true" data-aos-easing="ease-out-cubic" data-aos-duration="1200">
            <div className="col-12 col-md-6 mb-4">
              <Link to={`/portfolio/agrikore`}>
                {/* <img src={Agrikore} alt="" /> */}

                <LazyLoadImage
                  alt=""
                  src={Agrikore} />
              </Link>
            </div>
            <div className="col-12 col-md-6 mb-4">
                {/* <img src={HustleSasa} alt="" /> */}

                <LazyLoadImage
                  alt=""
                  src={HustleSasa} />
            </div>
          </div>

          <div className="row" data-aos="fade-up" data-aos-once="true" data-aos-easing="ease-out-cubic" data-aos-duration="1500">
            <div className="col-12 col-md-6 mb-4">
               {/* <img src={Ntl} alt="" /> */}

               <LazyLoadImage
                alt=""
                src={Ntl} />
            </div>
            <div className="col-12 col-md-6 mb-4">
              {/* <img src={Ecobank} alt="" /> */}

              <LazyLoadImage
                alt=""
                src={Ecobank} />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container brands-div text-center">
          <h4>OTHER PROJECTS</h4>

          <div className="row mt-5">
            <div className="col-12 col-sm-3 col-md-3">
              <a href="https://www.pesawise.com" target="_blank" rel="noreferrer">
                <img src={props.lightMode ? PW : PWW} alt="" />
              </a>
            </div>
            <div className="col-12 col-sm-3 col-md-3">
              <a href="https://play.google.com/store/apps/details?id=com.dtb.app&hl=en&gl=US" target="_blank" rel="noreferrer">
                <img src={props.lightMode ? DTB : DTBW} alt="" />
              </a>
            </div>
            <div className="col-12 col-sm-4 col-md-3">
              <a href="https://tingg.africa" target="_blank" rel="noreferrer">
                <img src={props.lightMode ?  Tingg : TinggW} alt="" />
              </a>
            </div>
            <div className="col-12 col-sm-4 col-md-3">
              <a href="https://apps.apple.com/ua/app/prospa-business-account/id1505367285" target="_blank" rel="noreferrer">
                <img src={props.lightMode ? Prospa : ProspaW} alt="" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container hr-div">
          <hr />
        </div>
      </section>

      <section>
        <Footer />
      </section>
    </div>
  );
}

export default Home;
