import DashBg from "../assets/img/dash/dashbg.png";
import HomeOne from "../assets/img/dash/homeone.png";
import HomeTwo from "../assets/img/dash/hometwo.png";
import HomeThree from "../assets/img/dash/homethree.png";

import SendOne from "../assets/img/dash/sendone.png";
import SendTwo from "../assets/img/dash/sendtwo.png";
import SendThree from "../assets/img/dash/sendthree.png";
import SendFour from "../assets/img/dash/sendfour.png";
import SendFive from "../assets/img/dash/sendfive.png";
import SendSix from "../assets/img/dash/sendsix.png";

import BillOne from "../assets/img/dash/billone.png";
import BillTwo from "../assets/img/dash/billtwo.png";
import BillThree from "../assets/img/dash/billthree.png";

import OnbOne from "../assets/img/dash/onbone.png";
import OnbTwo from "../assets/img/dash/onbtwo.png";
import OnbThree from "../assets/img/dash/onbthree.png";

export const dashMainTitleContent = {
    mainTitle: "Dash",
    mainText: "The all-in-one payments app to manage your financial life."
};

export const dashImageContent = {
    sectionImg: DashBg
};

export const dashOverviewContent = {
        overviewList: [
            {
                ovMainTitle: "Overview",
                workDuration: [
                    {
                        durItem: "2019 – 2020, Freelance Product Designer"
                    },
                    {
                        durItem: "2021 – Present, Head of Product Design"
                    }
                ],
                ovMainText: [
                    {
                        oMItem: "Dash is a unified and accessible payments platform built to increase efficiency and accessibility for the estimated 1.3 billion Africans currently transacting via digital payments. Creating a unique alternative payment network that brings together mobile money and traditional banks, to facilitate the processing of transactions for consumers and businesses."
                    }
                ],
            },
            {
                ovMainTitle: "Key achievements",
                workDuration: null,
                ovMainText: [
                    {
                        oMItem: "At Dash, I've navigated some of the biggest challenges in my career. I joined as sole product designer in 2019, during its initial stages. In the early 2021, I became the Head of Product Design, laying the foundation for Dash's product and visual design. I expanded the design team to three members across three countries."
                    },
                    {
                        oMItem: "I've taken the lead in design sprints and ideation sessions, while also developing a design system to facilitate knowledge sharing, maintain speed, and ensure consistency across the product organization as we scale."
                    },
                    {
                        oMItem: "My collaboration extended to agile teams, including Engineers, Product Leads, and Marketing. Together, we've diligently pursued the objective of assessing and enhancing the customer experience, driving growth, revenue, and retention."
                    },
                ],
            }
        ],
        role: [
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "Scaling the team"
            },
            {
                rolItem: "Design Leadership"
            },
        ],
        recognition: [
            {
                recogItem: "Ghanaian fintech Dash raises $32.8M seed to build connected wallets for Africans",
                recogLink: "https://techcrunch.com/2022/03/07/ghanaian-fintech-dash-raises-32-8m-seed-to-build-connected-wallets-for-africans/?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAGC6UpcB0dHYXahQQqAxNLPtx2ixLaiVOpSPKXvrhx-qjHo5h9K_IcXXqUU4Lc_oPoPGDgdCwYRz3dhXzpCnS6Xqvfs3I9dqzxq5ZDpj2ZvbD6HLf-nktLENwMGWI2xPmPok5hOD0FZrNYhOBVzrQAccLoMhMm8vsRsWi1aKsA18"
            }
        ]
};

export const dashDetailsContent = [
    {
        detailsTitle: "Homescreen architecture",
        paragraphList: [
            {
                subTitle: "The problem:",
                problemStatement: '“As a user, I would like it to be easier to keep track of my finances.”',
                subText: [],
            },
            {
                subTitle: "The solution:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "By integrating financial tools at its core, we provided users with a comprehensive and convenient experience for managing their finances. The app's home screen acts as a central hub, presenting users with a clear overview of their financial status. This includes their account balance, recent spending patterns, and upcoming financial obligations such as bills and payment requests. By offering quick access to features like sending and requesting money, along with effortless deposits and withdrawals, the app empowers users to stay in control of their financial affairs directly from their home screen."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Design Direction"
            },
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "UX/UI Design"
            }
        ],
        detailsFullSegmentImage: false,
        detailsSectionImage: null,
        detailsImages: [
            HomeOne, HomeTwo, HomeThree
        ],
        lastItem: false
    },
    {
        detailsTitle: "Redefining payments",
        paragraphList: [
            {
                subTitle: "The problem:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "Transform the payment process into a complex, multiplatform solution that provides advanced functionality in person-to-person and business payments. The solution should seamlessly cater to both local and global transfers."
                    }
                ],
            },
            {
                subTitle: "The solution:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "I built a versatile payments platform that allows users to send money instantly with just a few taps. Users can choose to scan a friend's QR code or input their unique 'Dashtag' for swift transactions. Infusing a personalized message or emoji adds a delightful and expressive element to each transaction. Additionally, users have the capability to make global payments, request funds from others, and instantly pay businesses."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Design Direction"
            },
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "UX/UI Design"
            }
        ],
        detailsFullSegmentImage: false,
        detailsSectionImage: null,
        detailsImages: [
            SendOne, SendTwo, SendThree, SendFour, SendFive, SendSix
        ],
        lastItem: false
    },
    {
        detailsTitle: "Simplifying bill payments",
        paragraphList: [
            {
                subTitle: "The problem:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "Current bill payment methods often result in disorganization, stress, and missed payments. Users struggle to keep track of their bills, leading to overdue payments. The absence of a streamlined and automated solution highlights the need for a more efficient system that simplifies bill scheduling, automates payments, and eliminates the burden of manual tracking."
                    }
                ],
            },
            {
                subTitle: "The solution:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "I created a versatile bill payment platform that streamlines bill management. Users can effortlessly manage automated payments without worrying about overdue or missed bills. They can conveniently schedule both recurring and one-time payments, which the system automatically deducts from their account balance when the bill is due. Furthermore, users can actively track their real-time balance and receive personalized alerts for bill due dates."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Design Direction"
            },
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "UX/UI Design"
            }
        ],
        detailsFullSegmentImage: false,
        detailsSectionImage: null,
        detailsImages: [
            BillOne, BillTwo, BillThree
        ],
        lastItem: false
    },
    {
        detailsTitle: "Magical onboarding flow",
        paragraphList: [
            {
                subTitle: "The problem:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "Develop a streamlined digital onboarding solution for a regulated banking service catering to users across Africa, ensuring compliance with KYC (Know Your Customer) regulations. The solution should prioritize user convenience, leverage technology for efficient identity verification, and adapt to the diverse regulatory landscape of African countries."
                    }
                ],
            },
            {
                subTitle: "The solution:",
                problemStatement: null,
                subText: [
                    {
                        stItem: "I've created a streamlined digital onboarding process to meet KYC compliance. Integrating biometrics like facial recognition ensures swift and secure identity verification. Users access services progressively through a tiered approach, complying with regulations. Tailored compliance modules for each country address diverse requirements. User-centric design, clear communication, and responsive support enhance the onboarding. This solution balances convenience, compliance, and accessibility for African users."
                    }
                ],
            }
        ],
        role: [
            {
                rolItem: "Design Direction"
            },
            {
                rolItem: "Product Design"
            },
            {
                rolItem: "UX/UI Design"
            }
        ],
        detailsFullSegmentImage: false,
        detailsSectionImage: null,
        detailsImages: [
            OnbOne, OnbTwo, OnbThree
        ],
        lastItem: true
    }
];